import axios from "axios";
import { useEffect, useState } from "react";
import { getDataSec, postDataSec, putDataSec } from "../../Apis/Api";
import { toast } from "react-toastify";
import { baseURL } from "../../config/config";

const ImageUpload = () => {
    const [images, setImages] = useState<{ url: string; date: string }[]>([]);
    const [loading, setLoading] = useState(false);
    const [previewImage, setPreviewImage] = useState<string | null>(null);
    const [copySuccess, setCopySuccess] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const fetchImages = async () => {
        try {
            const response = await getDataSec(baseURL + "/customImages");
            if (response.success) setImages(response.images);
        } catch (error) {
            console.error("Failed to fetch images:", error);
        }
    };

    useEffect(() => {
        fetchImages();
    }, []);

    const handleCopyLink = async () => {
        if (previewImage) {
            try {
                await navigator.clipboard.writeText(previewImage?.source);
                setCopySuccess(true);
                setTimeout(() => setCopySuccess(false), 2000);
            } catch (err) {
                console.error("Failed to copy link:", err);
            }
        }
    };
    const handleDelete = async () => {
        setDeleteLoading(true);
        try {
            const response = await getDataSec(
                baseURL + "/deleteCustomImage/" + previewImage?.filename
            );

            if (response.success) {
                setPreviewImage(null);
                await fetchImages();
            } else {
                console.error("Failed to delete image");
            }
        } catch (error) {
            console.error("Delete failed:", error);
        } finally {
            setDeleteLoading(false);
        }
    };

    return (
        <div
            style={{
                width: "80%",
                margin: "50px auto",
                fontFamily: "Arial, sans-serif",
            }}
        >
            <div style={{ marginBottom: "15px", textAlign: "center" }}>
                <input
                    type="file"
                    multiple
                    onChange={async (e: any) => {
                        if (Object.values(e.target.files)?.length === 0)
                            return toast.error("No file selected");
                        const fd = new FormData();
                        Object.values(e.target.files).forEach((file: any) => {
                            fd.append("image", file);
                        });
                        const result2 = await axios.put(
                            `${baseURL}/upload`,
                            fd,
                            {
                                headers: {
                                    "x-access-token":
                                        localStorage.getItem("token2"),
                                    "Content-Type": "multipart/form-data",
                                },
                            }
                        );
                        if (result2 && result2.data?.success) {
                            putDataSec(baseURL + "/customImages", {
                                images: [result2.data?.path?.filename],
                            }).then(() => {
                                e.target.value = null;
                                fetchImages();
                            });
                        } else {
                            toast.error("Image error");
                        }
                    }}
                    accept="image/*"
                />
                {loading && <p style={{ color: "blue" }}>Uploading...</p>}
            </div>

            <div
                style={{
                    display: "flex",
                    gap: "20px",
                    alignItems: "flex-start",
                }}
            >
                {/* Image List (Left) */}
                <div
                    style={{
                        flex: 1,
                        maxWidth: "500px",
                        border: "1px solid #ddd",
                        padding: "10px",
                        borderRadius: "8px",
                        backgroundColor: "#f9f9f9",
                        height: "400px",
                        overflowY: "auto",
                    }}
                >
                    <h3 style={{ textAlign: "center", marginBottom: "10px" }}>
                        Image List
                    </h3>
                    {images.map((image, index) => (
                        <div
                            key={index}
                            style={{
                                padding: "10px",
                                borderBottom: "1px solid #ddd",
                                cursor: "pointer",
                            }}
                            onClick={() =>
                                setPreviewImage({
                                    source: `${baseURL}/public/customImages/${image}`,
                                    filename: image,
                                })
                            }
                        >
                            <span
                                style={{
                                    color: "#007bff",
                                    textDecoration: "underline",
                                    textTransform: "lowercase",
                                }}
                            >
                                {image}
                            </span>
                            <p style={{ fontSize: "12px", color: "#555" }}>
                                {image.date}
                            </p>
                        </div>
                    ))}
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    {/* Image Preview (Right) */}
                    <div
                        style={{
                            flex: 2,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px solid #ddd",
                            padding: "10px",
                            borderRadius: "8px",
                            backgroundColor: "#fff",
                            width: "100%",
                        }}
                    >
                        {previewImage ? (
                            <>
                                <img
                                    src={previewImage?.source}
                                    alt="Preview"
                                    style={{
                                        maxWidth: "100%",
                                        maxHeight: "380px",
                                        borderRadius: "8px",
                                    }}
                                />
                            </>
                        ) : (
                            <p style={{ color: "#999", fontSize: "16px" }}>
                                Select an image to preview
                            </p>
                        )}
                    </div>

                    {previewImage && (
                        <div
                            style={{ display: "flex", flexDirection: "column" }}
                        >
                            <button
                                onClick={handleCopyLink}
                                style={{
                                    padding: "8px 16px",
                                    backgroundColor: copySuccess
                                        ? "#4CAF50"
                                        : "#2196F3",
                                    color: "white",
                                    border: "none",
                                    borderRadius: "4px",
                                    cursor: "pointer",
                                    transition: "background-color 0.3s",
                                }}
                            >
                                {copySuccess ? "Link Copied!" : "Copy Link"}
                            </button>
                            <button
                                onClick={handleDelete}
                                disabled={deleteLoading}
                                style={{
                                    marginTop: "10px",
                                    padding: "8px 16px",
                                    backgroundColor: "#f44336",
                                    color: "white",
                                    border: "none",
                                    borderRadius: "4px",
                                    cursor: "pointer",
                                    opacity: deleteLoading ? 0.7 : 1,
                                    transition: "opacity 0.3s",
                                }}
                            >
                                {deleteLoading ? "Deleting..." : "Delete Image"}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ImageUpload;
