import React, { useEffect, useRef, useState } from "react";
import { getDataSec, postDataSec } from "../../Apis/Api";
import { baseURL } from "../../config/config";
import { toast } from "react-toastify";
import "./Chatbot.css";
import Switch from "@mui/material/Switch";
import moment from "moment";
import {
    Box,
    Button,
    CircularProgress,
    Paper,
    Popper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from "@mui/material";
import { Check, Close, MoreVert, Send } from "@mui/icons-material";
const Chatbot = () => {
    const [ticketList, setTicketList] = useState([]);
    const [selectedTicket, setSelectedTicket] = useState();
    const [searchQuery, setSearchQuery] = useState("");
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const [totalTickets, setTotalTickets] = useState(0);
    const [messages, setMessages] = useState([]);
    const [ticketDetails, setTicketDetails] = useState();
    const inputBoxRef = useRef(null);
    const chatboxRef = useRef(null);
    const [ticketType, setTicketType] = useState("open");
    const [customerType, setCustomerType] = useState("");
    const [renderView, setRenderView] = useState(false);
    const TextingGif = "/assets/images/texting.gif";
    const timerRef = useRef(null);
    const timer2Ref = useRef(10);
    const [countdown, setCountdown] = useState(10);
    const [anchorEl, setAnchorEl] = useState(null);
    //const [data,setData]=useState(dataReslove)

    const dataReslove = [
        {
            id: 1,
            showMsg: "Call not picked",
            resolution: "Call not picked",
            message:
                "We tried reaching out to you over the call but couldn't connect. Please email us at support@aapkabazar.co",
            closeTicket: false,
        },
        {
            id: 2,
            showMsg: "Chat inactive",
            resolution: "Chat inactive",
            message:
                "Due to inactivity, this chat got automatically closed. Feel free to reach out to us anytime",
            closeTicket: true,
        },
        {
            id: 3,
            showMsg: "Resolved over call",
            resolution: "Resolved over call",
            message:
                "As discussed over the call, the issue has been resolved. If you have any further queries, feel free to reach out to us",
            closeTicket: true,
        },
    ];

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popper" : undefined;

    const handleSendMessage = (text) => {
        setLoading(true);
        setMessages((prev) => [
            ...prev,
            {
                message: text,
                loading: true,
                source: "server",
                date: Date.now(),
            },
        ]);
        const tempTick = ticketDetails?._id;
        postDataSec(baseURL + "/chatbot/resumeTicket", {
            ticketId: tempTick,
            message: text,
        })
            .then((res) => {
                if (res.success) {
                    setMessages(res.ticket?.chats);
                    setAnchorEl(null);
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const refreshChatbox = async (thisTicket) => {
        setMessages([]);
        setTicketDetails(null);
        getDataSec(`${baseURL}/chatbot/viewTicket?ticketId=${thisTicket._id}`)
            .then((res) => {
                setTicketDetails({
                    id: res.tickets?.id,
                    status: res.tickets?.ticketStatus,
                    _id: res.tickets?._id,
                    resolution: res.tickets?.resolution,
                });
                setMessages(res.tickets?.chats);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getTicketList = async () => {
        try {
            setTotalTickets(0);
            const result = await getDataSec(
                baseURL +
                    "/chatbot/listAll?limit=10&page=" +
                    page +
                    "&ticketType=" +
                    ticketType +
                    "&customerType=" +
                    customerType
            );
            if (result.success) {
                setTicketList(result.tickets);
                setTotalTickets(result.totalTickets);
                setTotalPages(result.totalPages);
            } else {
                toast.error("Unable to fetch brands");
                console.log(result.message);
            }
        } catch (err) {
            console.log("error in fetching tags:", err);
            toast.error("Error while fetching tags");
        }
    };

    const handleSearch = async () => {
        getDataSec(`${baseURL}/chatbot/viewTicket?ticketId=${searchQuery}`)
            .then((res) => {
                if (res.success) {
                    setTicketDetails({
                        id: res.tickets?.id,
                        status: res.tickets?.ticketStatus,
                        _id: res.tickets?._id,
                        resolution: res.tickets?.resolution,
                    });
                    setMessages(res.tickets?.chats);
                } else {
                    toast.error("Ticket not found");
                }
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message);
            })
            .finally(() => {
                setSearchQuery("");
            });
    };

    const handleOpenNew = async () => {
        const phone = prompt("Enter phone number of user");
        if (phone && phone.length === 10) {
            postDataSec(baseURL + "/chatbot/openNew", {
                phoneNo: phone,
            })
                .then((res) => {
                    if (res.success) {
                        getTicketList().then(() => {
                            setSelectedTicket(res.ticket);
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const handleResolve = async () => {
        let message = prompt("Enter message to user");
        if (!message) toast.error("Please enter a valid message");
        else
            postDataSec(baseURL + "/chatbot/resolveTicket", {
                ticketId: ticketDetails?._id,
                message,
            })
                .then((res) => {
                    if (res.success) {
                        toast.success("Ticket resolved.");
                        setSelectedTicket(null);
                        getTicketList();
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
    };

    const handleResolution = async (message) => {
        postDataSec(baseURL + "/chatbot/resolveTicket", {
            ticketId: ticketDetails?._id,
            message,
        })
            .then((res) => {
                if (res.success) {
                    toast.success("Ticket resolved.");
                    setSelectedTicket(null);
                    getTicketList();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleResolutionQuery = (pop) => {
        handleSendMessage(pop?.message);
        if (pop?.closeTicket) handleResolution(pop?.resolution);
        else setSelectedTicket(null);
    };

    useEffect(() => {
        if (selectedTicket) {
            refreshChatbox(selectedTicket);
        }
    }, [selectedTicket]);

    useEffect(() => {
        if (chatboxRef.current) {
            chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
        }
    }, [messages]);

    useEffect(() => {
        if (countdown === 0) {
            getTicketList();
            setCountdown(10);
        } else {
            timerRef.current = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);
        }
    }, [countdown]);

    useEffect(() => {
        getTicketList();
        document.body.style.overflow = "hidden";
        const permissions = JSON.parse(localStorage.getItem("permissions")).map(
            (item) => item.name
        );
        if (permissions.includes("chatbot")) {
            setRenderView(true);
        }
    }, [page, ticketType, customerType]);

    const TicketItem = ({ item, index }) => {
        const description =
            item?.user?.name?.length > 0 ? `${item.user?.name}` : "";

        return (
            <TableRow sx={{ m: 2 }} key={index}>
                <TableCell>{item.id}</TableCell>
                <TableCell sx={{ textTransform: "capitalize" }}>
                    {item.user?.phoneNo}

                    <p>{description?.length > 0 && description}</p>
                </TableCell>
                <TableCell
                    style={{
                        textTransform: "capitalize",
                        color:
                            item.ticketStatus === "resolved" ? "green" : "red",
                        fontWeight: "bold",
                    }}
                >
                    {item.ticketStatus}
                </TableCell>
                <TableCell>
                    {item.isOfflineCustomer ? "Walk-In" : "Online"}
                </TableCell>
                <TableCell>
                    <div>
                        <p>{moment(item.createdAt).format("DD MMM")}</p>
                        <p>{moment(item.createdAt).format("hh:mm A")}</p>
                    </div>
                </TableCell>
                <TableCell>
                    <Button
                        onClick={() => {
                            setSelectedTicket(null);
                            setSelectedTicket(item);
                        }}
                        variant="contained"
                        color="primary"
                        sx={{
                            "&:hover": {
                                backgroundColor: "#0A3B65",
                            },
                        }}
                    >
                        Chat
                    </Button>
                </TableCell>
            </TableRow>
        );
    };

    const RenderChatItem = ({ message, index }) => {
        return (
            <div
                key={message._id}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems:
                        message.source === "customer"
                            ? "flex-start"
                            : "flex-end",
                    margin: "8px 0",
                }}
            >
                <div
                    style={{
                        padding: "12px",
                        maxWidth: "75%",
                        borderRadius: "12px",
                        backgroundColor:
                            message?.source === "server"
                                ? "#E5E7EB"
                                : "#3B82F6",
                        color:
                            message?.source === "server"
                                ? message?.loading
                                    ? "#aaa"
                                    : "#1F2937"
                                : "white",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 4,
                    }}
                >
                    {message?.message}
                    {message?.images && (
                        <div className="flex flex-col gap-2 mt-2">
                            {message.images.map((btnText, idx) => {
                                return (
                                    <button
                                        key={idx}
                                        onClick={() =>
                                            window.open(
                                                `https://devfrontendapi.aapkabazar.co/api/public/chatbot/${ticketDetails?._id}/${btnText}`
                                            )
                                        }
                                    >
                                        {btnText}
                                    </button>
                                );
                            })}
                        </div>
                    )}
                    {message?.buttons && (
                        <div className="flex flex-col gap-2 mt-2">
                            {message.buttons.map((btnText, idx) => {
                                return (
                                    <p
                                        style={{
                                            marginTop: 10,
                                            color: "#3B82F6",
                                            fontWeight: "bold",
                                            textTransform: "capitalize",
                                            fontStyle: "italic",
                                        }}
                                        disabled
                                        key={idx}
                                    >
                                        {" > "}
                                        {btnText?.title}
                                    </p>
                                );
                            })}
                        </div>
                    )}
                    {message?.loading && (
                        <CircularProgress
                            size={16}
                            color="inherit"
                            style={{
                                color: "#aaa",
                            }}
                        />
                    )}
                </div>
                {!message?.loading && (
                    <p
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            navigator.clipboard
                                .writeText(message.message)
                                .then(() => {
                                    toast.success(
                                        "Message copied to clipboard"
                                    );
                                })
                                .catch((err) => {
                                    toast.error("Failed to copy message");
                                });
                        }}
                        className="dateFormat"
                    >
                        {moment(message.date).format("ddd DD MMM hh:mm A")}
                        📌
                    </p>
                )}
            </div>
        );
    };

    function CircularProgressWithLabel(props) {
        return (
            <Box sx={{ position: "relative", display: "inline-flex" }}>
                <CircularProgress variant="determinate" {...props} />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Typography
                        variant="caption"
                        component="div"
                        sx={{ color: "text.secondary" }}
                    >
                        {`${Math.round(props.value / 10)}s`}
                    </Typography>
                </Box>
            </Box>
        );
    }

    return !renderView ? (
        <div>
            <center>
                <h1>Chatbot Disabled for this account</h1>
            </center>
        </div>
    ) : (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
                padding: 20,
                gap: 20,
            }}
        >
            <div className="tagListContainer">
                <div
                    style={{
                        display: "flex",
                        // maxHeight: 60,
                        marginBottom: 20,
                        // flex: 1,
                        gap: 20,
                        // justifyContent: "space-between",
                    }}
                >
                    {/* <h3>Chats List</h3> */}
                    <input
                        type="text"
                        onSubmit={(e) => {
                            if (e.key === "Enter") {
                                handleSearch();
                            }
                        }}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleSearch();
                            }
                        }}
                        value={searchQuery}
                        onChange={(e) => {
                            setSearchQuery(e.target.value);
                        }}
                        placeholder="Search Ticket ID"
                        style={{
                            padding: 10,
                            // flex: 1,
                            borderRadius: 8,
                            border: "1px solid #aaa",
                        }}
                    />
                    <CircularProgressWithLabel
                        variant="determinate"
                        value={100 - (countdown / 10) * 100}
                    />
                    <div
                        style={{
                            // justifyContent: "space-around",
                            gap: 4,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "row",
                            }}
                        >
                            <select
                                value={ticketType}
                                onChange={(e) => setTicketType(e.target.value)}
                            >
                                <option value="all">All Tickets</option>
                                <option value="open">Open Tickets</option>
                                <option value="closed">Closed Tickets</option>
                                <option value="resolved">
                                    Resolved Tickets
                                </option>
                            </select>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "row",
                            }}
                        >
                            <select
                                value={customerType}
                                onChange={(e) =>
                                    setCustomerType(e.target.value)
                                }
                            >
                                <option value="all">All Customers</option>
                                <option value="online">Online Customers</option>
                                <option value="offline">
                                    Offline Customers
                                </option>
                            </select>
                        </div>
                    </div>
                    <TablePagination
                        labelRowsPerPage=""
                        rowsPerPageOptions={[]}
                        style={{ alignSelf: "center" }}
                        component="div"
                        count={totalPages * 10}
                        rowsPerPage={10}
                        page={page}
                        onPageChange={(e, i) => {
                            setPage(i);
                        }}
                        // onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
                {loading ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <CircularProgress size={48} color="info" />{" "}
                    </div>
                ) : (
                    <TableContainer
                        component={Paper}
                        style={{ overflowX: "scroll" }}
                    >
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ textAlign: "left" }}>
                                        ID
                                    </TableCell>
                                    <TableCell style={{ textAlign: "left" }}>
                                        Phone
                                    </TableCell>
                                    <TableCell style={{ textAlign: "left" }}>
                                        Status
                                    </TableCell>
                                    <TableCell style={{ textAlign: "left" }}>
                                        Type
                                    </TableCell>
                                    <TableCell style={{ textAlign: "left" }}>
                                        Date
                                    </TableCell>
                                    <TableCell style={{ textAlign: "left" }}>
                                        Actions
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody style={{}}>
                                {ticketList.map((item, index) => (
                                    <TicketItem
                                        key={index}
                                        item={item}
                                        index={index}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </div>
            <div className="tagEditorContainer">
                {selectedTicket ? (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ margin: 4, padding: 4 }}>
                            <table
                                style={{
                                    borderCollapse: "collapse",
                                    width: "100%",
                                }}
                            >
                                <tbody>
                                    <tr
                                        style={{
                                            borderBottom: "1px solid black",
                                        }}
                                    >
                                        <td
                                            style={{
                                                padding: 4,
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            #{ticketDetails?.id}
                                        </td>
                                        <td
                                            style={{
                                                padding: 4,
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            {ticketDetails?.status}
                                        </td>
                                        <td
                                            style={{
                                                padding: 4,
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            {ticketDetails?.status !==
                                            "resolved" ? (
                                                <Button
                                                    endIcon={
                                                        <Check color="primary" />
                                                    }
                                                    onClick={handleResolve}
                                                    variant="outlined"
                                                    color="primary"
                                                    style={{
                                                        fontSize: 12,
                                                    }}
                                                >
                                                    Mark as Solved
                                                </Button>
                                            ) : (
                                                ticketDetails?.resolution
                                            )}
                                        </td>
                                        <td
                                            style={{
                                                padding: 4,
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            <Button
                                                endIcon={
                                                    <Close color="primary" />
                                                }
                                                onClick={() => {
                                                    setSelectedTicket(null);
                                                }}
                                                variant="outlined"
                                                color="primary"
                                                style={{
                                                    fontSize: 12,
                                                }}
                                            >
                                                Close
                                            </Button>
                                        </td>
                                        {ticketDetails?.status?.toLowerCase() !==
                                            "resolved" && (
                                            <td
                                                style={{
                                                    padding: 4,
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                <MoreVert
                                                    color="primary"
                                                    onClick={handleClick}
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />

                                                <Popper
                                                    id={id}
                                                    open={open}
                                                    anchorEl={anchorEl}
                                                >
                                                    <Box
                                                        sx={{
                                                            cursor: "pointer",
                                                            bgcolor:
                                                                "background.paper",
                                                        }}
                                                    >
                                                        {dataReslove.map(
                                                            (pop) => {
                                                                return (
                                                                    <Typography
                                                                        sx={{
                                                                            p: 2,
                                                                        }}
                                                                        key={
                                                                            pop?.id
                                                                        }
                                                                        onClick={() =>
                                                                            handleResolutionQuery(
                                                                                pop
                                                                            )
                                                                        }
                                                                    >
                                                                        {
                                                                            pop?.showMsg
                                                                        }
                                                                    </Typography>
                                                                );
                                                            }
                                                        )}
                                                    </Box>
                                                </Popper>
                                            </td>
                                        )}
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div
                            ref={chatboxRef}
                            style={{
                                overflowY: "scroll",
                                maxHeight: "50vh",
                                padding: "8px",
                                backgroundColor: "white",
                            }}
                        >
                            {messages?.map((message, index) => (
                                <RenderChatItem
                                    message={message}
                                    index={index}
                                />
                            ))}
                            {loading && (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        margin: "8px 0",
                                    }}
                                >
                                    <div
                                        style={{
                                            padding: "12px",
                                            maxWidth: "256px",
                                            color: "white",
                                        }}
                                    >
                                        <img
                                            height={50}
                                            width={50}
                                            src={TextingGif}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        {messages &&
                            messages?.length >= 1 &&
                            ticketDetails?.status !== "resolved" &&
                            !loading && (
                                <div
                                    style={{
                                        display: "flex",
                                        borderTop: "1px solid black",
                                        paddingTop: 4,
                                        margin: 4,
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        gap: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: 1,
                                            border: "2px solid #D1D5DB",
                                            display: "flex",
                                            padding: "8px",
                                            borderRadius: "8px",
                                        }}
                                    >
                                        <input
                                            ref={inputBoxRef}
                                            type="text"
                                            placeholder="Type a message"
                                            style={{
                                                flex: 1,
                                                outline: "none",
                                                border: "none",
                                            }}
                                            onKeyDown={(e) => {
                                                if (
                                                    e.key === "Enter" &&
                                                    e.target.value.trim()
                                                ) {
                                                    handleSendMessage(
                                                        e.target.value.trim()
                                                    );
                                                    e.target.value = "";
                                                }
                                            }}
                                        />
                                        <div
                                            onClick={() => {
                                                if (
                                                    inputBoxRef.current.value.trim() &&
                                                    !loading
                                                ) {
                                                    handleSendMessage(
                                                        inputBoxRef.current.value.trim()
                                                    );
                                                    inputBoxRef.current.value =
                                                        "";
                                                }
                                            }}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <Send color="primary" />
                                        </div>
                                    </div>
                                </div>
                            )}
                    </div>
                ) : (
                    <p>
                        Click on <strong>Chat button</strong> to open a specific
                        chat
                    </p>
                )}
            </div>
        </div>
    );
};

export default Chatbot;
