import { useEffect, useState } from "react";
import { ProductCard } from "./ProductCard.tsx";
import React from "react";

const AllProductList = ({
    page,
    loadingProducts,
    products,
    setProducts,
    noMoreProducts,
    disablebuttons,
    setSelectedProductId,
}) => {
    return (
        <div id="ProductListContainer">
            <div className="product-header">
                <h3>All Products</h3>
            </div>
            <div id="productList">
                <table>
                    <thead className="headerRow">
                        <tr>
                            <th style={{ textAlign: "left" }}>Sr no.</th>
                            <th style={{ textAlign: "left" }}>Image</th>
                            <th
                                style={{ textAlign: "left" }}
                                className="product-name-header"
                            >
                                Product Name
                            </th>
                            <th style={{ textAlign: "right" }}>Attribute</th>
                            <th style={{ textAlign: "center" }}>Weight</th>
                            <th style={{ textAlign: "left" }}>SKU</th>
                            <th style={{ textAlign: "left" }}>HSN Code</th>
                            <th style={{ textAlign: "left" }} colSpan={3}>
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {products.map((item, index) => (
                            <ProductCard
                                key={item._id}
                                item={item}
                                index={index + 1}
                                disablebuttons={disablebuttons}
                            />
                        ))}
                    </tbody>
                </table>
                {noMoreProducts && (
                    <p className="no-products">No More Orders</p>
                )}
                {loadingProducts && <div className="loader"></div>}
            </div>
        </div>
    );
};

export default AllProductList;
