const checkForValidPermissions = (permissionName) => {
    let perm = localStorage.getItem("permissions");
    let returnObj = {
        read: false,
        modify: false,
    };
    if (perm) {
        perm = JSON.parse(perm);
        if (
            perm.some(
                (permission) =>
                    (permission.name === permissionName &&
                        permission.read === true) ||
                    permission.name === "admin"
            )
        ) {
            returnObj.read = true;
            if (
                perm.some(
                    (permission) =>
                        (permission.name === permissionName &&
                            permission.modify === true) ||
                        permission.name === "admin"
                )
            )
                returnObj.modify = true;
        } else {
            returnObj.read = false;
        }
    }

    return returnObj;
};

export { checkForValidPermissions };
