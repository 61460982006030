import React, { useEffect, useState } from "react";
import "./OrderDetails.css";
import { useLocation, useNavigate } from "react-router-dom";
import BackSvg from "../../assets/back.svg";
import { baseURL } from "../../config/config";
import { toast } from "react-toastify";
import JobCard from "../Jobs/JobCard";
import { OrderProductList } from "./OrderProductList";
import { getDataSec } from "../../Apis/Api";

const OrderDetails = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [orderData, setOrderData] = useState(null);
    const [jobData, setJobData] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(false);
    const edit = queryParams.get("edit");

    const [isEditable, setIsEditable] = useState(edit || false);
    const [products, setProducts] = useState([]);
    const [showChange, setShowChange] = useState(false);

    const id = queryParams.get("id");
    const refreshPage = () => {
        window.location.reload();
    };

    const handleBackBtn = () => {
        navigate(-1);
    };

    const getData = async () => {
        if (!loading) {
            setLoading(true);
            const result = await getDataSec(`${baseURL}/order?orderId=${id}`);
            if (result.success) {
                setOrderData({ ...result.order });
                setProducts([...result.order.products]);
            } else {
                console.log(result.message);
            }
            setLoading(false);
        }
    };

    const getJobData = async () => {
        try {
            const result = await getDataSec(
                `${baseURL}/jobs/details?orderNo=${id}`
            );
            if (result.success) {
                setJobData(result.job);
            }
        } catch (error) {
            console.log(error);
            toast.error("Error");
        }
    };
    useEffect(() => {
        getData();
        getJobData();
    }, []);
    function convertTimeToIST(zuluTime) {
        return new Date(zuluTime).toLocaleString("en-IN", {
            timeZone: "Asia/Kolkata",
        });
    }

    if (!orderData) {
        return <></>;
    }

    return (
        <div id="orderDetailsRootContainer">
            <div id="topBtnsDiv">
                <button id="backBtn" onClick={handleBackBtn}>
                    <img src={BackSvg} style={{ width: 20, height: 20 }} />
                    Back
                </button>
                <div style={{ display: "flex", gap: 20 }}>
                    <button
                        id="DownloadOrderBtn"
                        onClick={() =>
                            navigate(`/customerdetails/${orderData?.userId}`)
                        }
                    >
                        View Customer
                    </button>
                </div>
            </div>

            <div id="orderDetailsContainer">
                <div id="sellerDetails">
                    <h2>Seller Details</h2>
                    <div className="line"></div>
                    <h4>Aap ka Bazar</h4>
                    <span>
                        {orderData?.seller.sellerInformation.fullAddress}
                    </span>
                    <h4>
                        Landmark:{" "}
                        <span>
                            {orderData?.seller.sellerInformation.landmark}
                        </span>
                    </h4>
                    <span>{orderData?.seller.phoneNo}</span>
                    <span style={{ fontWeight: "bold", fontSize: 24 }}>
                        Gross Profit : ₹{orderData?.grossProfit}
                    </span>
                    {orderData?.customerMessage ? (
                        <span
                            style={{
                                marginTop: 40,
                                backgroundColor: "#ff00002e",
                                border: "1px solid red",
                                padding: "5px",
                                borderRadius: 8,
                            }}
                        >
                            {" "}
                            Customer Message : {orderData.customerMessage}
                        </span>
                    ) : null}
                </div>
                <div id="orderDetails">
                    <h2>Order Details</h2>
                    <div className="line"></div>
                    <h4>{orderData?.address.name}</h4>
                    <span>{orderData?.address.line1}</span>
                    <span>{orderData?.address.line2}</span>
                    <span>{orderData?.address.mobileNo}</span>
                    <div className="line"></div>
                    <h4>
                        Order ID: <span>{orderData?.id}</span>
                    </h4>
                    <h4>
                        {orderData.paymentSource &&
                            Object.keys(orderData.paymentSource).map((mode) => {
                                if (orderData.paymentSource[mode] === 0)
                                    return <></>;
                                return (
                                    <p>
                                        {mode} :
                                        <span>
                                            {" "}
                                            {JSON.stringify(
                                                orderData.paymentSource[mode]
                                            )}
                                        </span>
                                    </p>
                                );
                            })}
                    </h4>
                    <h4>
                        Order Status: <span>{orderData?.status}</span>
                    </h4>
                </div>
            </div>

            <div id="dateDiv">
                <h4>
                    Order Date: <span>{convertTimeToIST(orderData?.date)}</span>
                </h4>
                <h4>
                    Delivery Date:{" "}
                    <span>{convertTimeToIST(orderData?.deliveryDate)}</span>
                </h4>
                <h4>
                    Delivery Slot: <span>{orderData?.deliveryTime?.slot}</span>
                </h4>
            </div>

            {orderData.status !== "confirmed" &&
            orderData.status !== "pending" ? (
                jobData ? (
                    <div
                        style={{
                            margin: "0 20px",
                            backgroundColor: "transparent",
                            borderRadius: 8,
                        }}
                    >
                        <JobCard item={jobData} />
                    </div>
                ) : (
                    <div
                        style={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "space-between",
                        }}
                    >
                        <p>Job not created</p>
                    </div>
                )
            ) : (
                <></>
            )}

            <div id="productList">
                {isEditable ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            gap: 20,
                        }}
                    >
                        <button
                            onClick={() => setModalOpen(true)}
                            style={{
                                padding: "10px 20px",
                                borderRadius: 8,
                                borderColor: "rgb(255 254 216)",
                                boxShadow: "none",
                                backgroundColor: "#f9f984",
                            }}
                        >
                            Add Product
                        </button>
                        <button
                            onClick={() => setShowChange(true)}
                            style={{
                                padding: "10px 20px",
                                borderRadius: 8,
                                borderColor: "#51ff8c",
                                boxShadow: "none",
                                backgroundColor: "#9fff9a",
                            }}
                        >
                            Confirm
                        </button>
                        <button
                            onClick={() => {
                                refreshPage();
                            }}
                            style={{
                                padding: "10px 20px",
                                borderRadius: 8,
                                borderColor: "rgb(253, 253, 239)",
                                boxShadow: "none",
                                backgroundColor: "rgb(253, 253, 239)",
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                ) : null}
                <table>
                    <thead className="headerRow">
                        <tr>
                            <th>Sr</th>
                            <th>Item/SKU Code</th>
                            <th>Product Name</th>
                            <th>purchasePrice(₹)</th>
                            <th>MRP(₹)</th>
                            <th>Sell Price(₹)</th>
                            <th>Total Qty</th>
                            <th>Total</th>
                            {isEditable ? <th>Action</th> : null}
                        </tr>
                    </thead>
                    <tbody>
                        {products?.map((item, index) => (
                            <OrderProductList
                                index={index}
                                item={item}
                                isEditable={isEditable}
                                products={products}
                                setProducts={setProducts}
                            />
                        ))}
                    </tbody>
                </table>
            </div>

            <div id="dateDiv">
                <h4>
                    Total Items: <span>{orderData?.products.length}</span>
                </h4>
                <h4>
                    Delivery Charge: <span>{orderData?.deliveryCharge}</span>
                </h4>
                {orderData?.offerId && orderData?.offerId !== null ? (
                    <h4>
                        PromoCode Discount:{" "}
                        <span>{orderData?.couponDiscount}</span>
                    </h4>
                ) : null}
                {orderData?.smallCartFee ? (
                    <h4>
                        Small Cart Fee: <span>{orderData?.smallCartFee}</span>
                    </h4>
                ) : null}
                <h4>
                    Total Amount: <span>{orderData?.amount}</span>
                </h4>
            </div>
        </div>
    );
};

export default OrderDetails;
