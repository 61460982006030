import React, { useEffect, useMemo, useState } from "react";
import "./Products.css";
import AllProductList from "./AllProductList.tsx";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { getDataSec, postDataSec } from "../../Apis/Api.js";
import { baseURL, pageLimit } from "../../config/config.js";
import Papa from "papaparse";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import { SingleProductNewPage } from "./SingleProductNewPage.tsx";
const Products = () => {
    const [tree, setTree] = useState([]);
    const [bulkUpload, setBulkUpload] = useState(null);
    const bulkUploadRef = React.useRef(null);
    const [bulkImportLoading, setBulkImportLoading] = useState();
    const [brandName, setBrandName] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [products, setProducts] = useState([]);
    const [noMoreProducts, setNoMoreProducts] = useState(false);
    const [loadingProducts, setLoadingProducts] = useState(false);
    const [page, setPage] = useState(0);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [disableButtons, setDisableButtons] = useState(false);
    const [exporting, setExporting] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedSubCategory, setSelectedSubCategory] = useState("");
    const [selectedLeafCategory, setSelectedLeafCategory] = useState("");
    const [selectedBrand, setSelectedBrand] = useState("");
    const [selectedSubBrand, setSelectedSubBrand] = useState("");
    const [subCategories, setSubCategories] = useState([]);
    const [leafCategories, setLeafCategories] = useState([]);
    const [subBrands, setSubBrands] = useState([]);
    const navigate = useNavigate();

    const handleFileUpload = (file) => {
        setBulkImportLoading(true);
        Papa.parse(file, {
            header: true,
            complete: function (results) {
                const filteredData = results.data
                    .map((row) => {
                        Object.keys(row).forEach((key) => {
                            if (row[key] === "FALSE") {
                                row[key] = false;
                            }
                            if (row[key] === "TRUE") {
                                row[key] = true;
                            }
                        });
                        return row;
                    })
                    .filter((row) =>
                        Object.values(row).every(
                            (value) => value !== null && value !== ""
                        )
                    );
                postDataSec(baseURL + "/product/add/bulk", {
                    products: filteredData,
                })
                    .then((res) => {
                        if (res && res.success) {
                            setBulkImportLoading(false);
                            getData().then();
                            toast.success(res?.message || "Import Successfull");
                        } else {
                            toast.info(res?.message || "Something went wrong");
                        }
                        setBulkImportLoading(false);
                    })
                    .catch((err) => {
                        toast.error(err?.message || "Error in bulk upload");
                        console.log("Error in bulk upload", err);
                        setBulkImportLoading(false);
                    });
                setBulkUpload(null);
            },
            error: function (error) {
                console.error("Error parsing CSV file: ", error);
                setBulkUpload(null);
            },
        });
    };
    const exportData = async () => {
        try {
            setExporting(true);
            const response = await fetch(`${baseURL}/product/exportCsv`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": localStorage.getItem("token2"),
                },
            });
            if (!response.ok) {
                return toast.error("Error combining data");
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            const fileName = `Seller Products ${moment(new Date()).format(
                "DD MMM"
            )}.csv`;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            toast.error("Error exporting data");
            console.log("Error exporting data", error);
        } finally {
            setExporting(false);
        }
    };
    const getData = async () => {
        setProducts([]);
        setIsSearching(true);
        if (!isSearching && !noMoreProducts) {
            setLoadingProducts(true);
            try {
                let apiUrl = `${baseURL}/product/list?page=${page}&limit=20`;
                if (searchKeyword.length > 0)
                    apiUrl += `&keyword=${encodeURIComponent(
                        searchKeyword.toLowerCase().trim()
                    )}`;
                if (selectedCategory)
                    apiUrl += `&rootCategoryId=${selectedCategory}`;
                if (selectedSubCategory)
                    apiUrl += `&subCategoryId=${selectedSubCategory}`;
                if (selectedLeafCategory)
                    apiUrl += `&leafCategoryId=${selectedLeafCategory}`;
                if (selectedBrand) apiUrl += `&brandId=${selectedBrand}`;
                if (selectedSubBrand)
                    apiUrl += `&subBrandId=${selectedSubBrand}`;

                const result = await getDataSec(apiUrl);
                if (result.success) {
                    if (page === 0) {
                        setProducts(result.products);
                    } else {
                        setProducts((prev) => [...prev, ...result.products]);
                    }
                } else {
                    toast.info(result.message);
                    setIsSearching(false);
                }
            } catch (error) {
                console.error("Error fetching data");
            } finally {
                setLoadingProducts(false);
                setIsSearching(false);
            }
        }
    };
    const handleSearch = () => {
        if (page === 0) {
            getData().then();
        } else {
            setPage(0);
        }
    };
    const clearFilters = () => {
        window.location.reload();
    };
    useEffect(() => {
        const getCategories = async () => {
            const result = await getDataSec(baseURL + "/brands");
            if (result.success) {
                setBrandName(result.brand);
            } else {
                console.log("Brand Names cannot be fetched");
            }
            const result2 = await getDataSec(baseURL + "/category/tree/v2");
            if (result2.success === true) {
                setTree(result2.category);
            } else {
                console.log("error in fetching categories", result.message);
            }
        };
        getCategories();
        getData().then();
        getDataSec(`${baseURL}/category`).then((response) => {
            if (response?.data) {
                setBrandName(response.data);
            }
        });
        getDataSec(`${baseURL}/brands`).then((response) => {
            if (response?.data) {
                setBrandName(response?.data);
            }
        });
        let perm = localStorage.getItem("permissions");
        if (perm) {
            perm = JSON.parse(perm);
            if (
                perm.some(
                    (permission) =>
                        (permission.name === "products" &&
                            permission.read === true) ||
                        permission.name === "admin"
                )
            ) {
                if (
                    perm.some(
                        (permission) =>
                            (permission.name === "products" &&
                                permission.modify === true) ||
                            permission.name === "admin"
                    )
                )
                    setDisableButtons(false);
                else setDisableButtons(true);
            } else {
                navigate("/404");
            }
        }
    }, []);
    useEffect(() => {
        if (bulkUpload) {
            handleFileUpload(bulkUpload);
        }
    }, [bulkUpload]);
    useEffect(() => {
        if (selectedCategory) {
            // window.alert("Root cat changed") // for the pop up
            getDataSec(
                `${baseURL}/category/subcategory?categoryId=${selectedCategory}`
            ).then((response) => {
                if (response && response.success) {
                    const filteredItems = response?.category?.filter(
                        (item) => !item.isLeaf
                    );
                    setSubCategories(filteredItems);
                    setLeafCategories([]); // Reset leaf categories
                    setSelectedSubCategory(""); // Reset subcategory selection}
                }
            });
        } else {
            setSubCategories([]);
            setLeafCategories([]);
        }
    }, [selectedCategory]);
    useEffect(() => {
        if (selectedSubCategory) {
            getDataSec(
                `${baseURL}/category/subcategory?categoryId=${selectedSubCategory}`
            ).then((response) => {
                if (response && response.success)
                    setLeafCategories(response?.category);
            });
        } else {
            setLeafCategories([]);
        }
    }, [selectedSubCategory]);

    useEffect(() => {
        if (selectedBrand) {
            getDataSec(`${baseURL}/brand?brandId=${selectedBrand}`).then(
                (response) => {
                    if (response && response.success)
                        setSubBrands(response?.brand?.subBrands);
                }
            );
        } else {
            setSubBrands([]);
        }
    }, [selectedBrand]);
    useEffect(() => {
        getData().then();
    }, [page]);
    return (
        <div>
            <div
                style={{
                    backgroundColor: "rgb(255, 255, 255)",
                    padding: "15px",
                    gap: 10,
                    margin: "20px",
                    borderRadius: "10px",
                    boxShadow: "0px 0px 10px 0px #0000001A",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <div
                    style={{
                        flexDirection: "row",
                        display: "flex",
                        flex: 1,
                        gap: 20,
                        border: "0px solid red",
                    }}
                ></div>
                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "row",
                        gap: 20,
                    }}
                >
                    <div style={{ flex: 0.6, display: "flex", gap: 20 }}>
                        <input
                            style={{
                                flex: 5,
                                paddingLeft: 10,
                                border: "1px solid #eee",
                            }}
                            placeholder="Search by Product Name , SKU , Barcode"
                            value={searchKeyword}
                            onKeyDownCapture={(e) => {
                                if (e.key === "Enter") {
                                    handleSearch();
                                }
                            }}
                            onChange={(e) => {
                                setSearchKeyword(e.target.value);
                            }}
                        />
                        <div style={{ width: 20 }}>
                            {isSearching && (
                                <CircularProgress
                                    size={18}
                                    sx={{ color: "#aaa" }}
                                />
                            )}
                        </div>
                    </div>
                    <div
                        style={{
                            flexDirection: "row",
                            gap: 20,
                            flex: 0.4,
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flex: 0.5,
                                gap: 20,
                                justifyContent: "flex-end",
                            }}
                        >
                            <button
                                disabled={bulkImportLoading}
                                onClick={() => {
                                    handleSearch();
                                }}
                                style={{
                                    cursor: "pointer",
                                    backgroundColor: "#ffef03",
                                    color: "#000",
                                    borderWidth: 1,
                                    padding: 10,
                                    fontSize: 14,
                                    width: "50%",
                                    borderRadius: 10,
                                    borderStyle: "solid",
                                    borderColor: "#dacc00",
                                }}
                            >
                                Search
                            </button>
                            <input
                                ref={bulkUploadRef}
                                onChange={(e) => {
                                    setBulkUpload(e.target.files[0]);
                                }}
                                type="file"
                                style={{ display: "none" }}
                            />
                            <button
                                disabled={bulkImportLoading}
                                onClick={() => {
                                    if (bulkUpload) {
                                        bulkUploadRef.current.value = "";
                                    } else {
                                        bulkUploadRef?.current?.click();
                                    }
                                }}
                                style={{
                                    cursor: "pointer",
                                    backgroundColor: bulkImportLoading
                                        ? "#eee"
                                        : "#e7f5f0",
                                    color: "#000",
                                    borderWidth: bulkImportLoading ? 0 : 1,
                                    padding: 10,
                                    fontSize: 14,
                                    width: "50%",
                                    borderRadius: 10,
                                    borderStyle: "solid",
                                    borderColor: "#0d9e67",
                                }}
                            >
                                {bulkImportLoading ? (
                                    <CircularProgress
                                        size={18}
                                        sx={{ color: "#aaa" }}
                                    />
                                ) : (
                                    "Import"
                                )}
                            </button>
                            <button
                                disabled={exporting}
                                onClick={exportData}
                                style={{
                                    cursor: "pointer",
                                    backgroundColor: exporting
                                        ? "#eee"
                                        : "#f2f2f2",
                                    color: "#000",
                                    borderWidth: exporting ? 0 : 1,
                                    padding: 10,
                                    fontSize: 14,
                                    width: "50%",
                                    borderRadius: 10,
                                    borderStyle: "solid",
                                    borderColor: "#cecece",
                                }}
                            >
                                {exporting ? (
                                    <CircularProgress
                                        size={18}
                                        sx={{ color: "#aaa" }}
                                    />
                                ) : (
                                    "Export"
                                )}
                            </button>
                            <button
                                onClick={() => {
                                    navigate("/addProduct");
                                }}
                                style={{
                                    cursor: "pointer",
                                    backgroundColor: "#0D9E67",
                                    color: "#fff",
                                    borderWidth: 1,
                                    padding: 10,
                                    fontSize: 14,
                                    width: "50%",
                                    borderRadius: 10,
                                    borderStyle: "solid",
                                    borderColor: "#0D9E67",
                                }}
                            >
                                Add
                            </button>
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        padding: "10px",
                        backgroundColor: "#f5f5f5",
                        borderRadius: "8px",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <select
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        style={{ padding: "8px", borderRadius: "5px" }}
                    >
                        <option value="">Select Category</option>
                        {tree
                            .filter((item) => item.isActive)
                            .map((category) => (
                                <option key={category._id} value={category._id}>
                                    {category.name}
                                </option>
                            ))}
                    </select>

                    <select
                        value={selectedSubCategory}
                        onChange={(e) => setSelectedSubCategory(e.target.value)}
                        style={{ padding: "8px", borderRadius: "5px" }}
                    >
                        <option value="">Select Sub-Category</option>

                        {subCategories.map((sub) => (
                            <option key={sub._id} value={sub._id}>
                                {sub.name}
                            </option>
                        ))}
                    </select>

                    <select
                        value={selectedLeafCategory}
                        onChange={(e) =>
                            setSelectedLeafCategory(e.target.value)
                        }
                        style={{ padding: "8px", borderRadius: "5px" }}
                    >
                        <option value="">Select Leaf Category</option>
                        {leafCategories.map((leaf) => (
                            <option key={leaf._id} value={leaf._id}>
                                {leaf.name}
                            </option>
                        ))}
                    </select>

                    <select
                        value={selectedBrand}
                        onChange={(e) => setSelectedBrand(e.target.value)}
                        style={{ padding: "8px", borderRadius: "5px" }}
                    >
                        <option value="">Select Brand</option>
                        {brandName.map((brand) => (
                            <option key={brand._id} value={brand._id}>
                                {brand.name}
                            </option>
                        ))}
                    </select>

                    <select
                        value={selectedSubBrand}
                        onChange={(e) => setSelectedSubBrand(e.target.value)}
                        style={{ padding: "8px", borderRadius: "5px" }}
                    >
                        <option value="">Select Sub-Brand</option>
                        {subBrands.map((subBrand) => (
                            <option key={subBrand._id} value={subBrand._id}>
                                {subBrand.name}
                            </option>
                        ))}
                    </select>

                    <button
                        onClick={() => {
                            setPage(0);
                        }}
                        style={{
                            padding: "8px 15px",
                            backgroundColor: "#000",
                            color: "#fff",
                            borderRadius: "5px",
                            cursor: "pointer",
                        }}
                    >
                        Apply
                    </button>

                    <button
                        onClick={clearFilters}
                        style={{
                            padding: "8px 15px",
                            backgroundColor: "#ddd",
                            color: "#000",
                            borderRadius: "5px",
                            cursor: "pointer",
                        }}
                    >
                        Clear
                    </button>
                </div>
            </div>
            <AllProductList
                page={page}
                loadingProducts={loadingProducts}
                products={products}
                noMoreProducts={noMoreProducts}
                disablebuttons={disableButtons}
            />
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                    backgroundColor: "rgb(255, 255, 255)",
                    padding: "15px",
                    gap: 10,
                    margin: "20px",
                    borderRadius: "10px",
                    boxShadow: "0px 0px 10px 0px #0000001A",
                    display: "flex",
                    flexDirection: "row",
                }}
            >
                <button
                    onClick={() => setPage((prev) => Math.max(prev - 1, 0))}
                    disabled={page === 0}
                    style={{
                        padding: "10px 20px",
                        marginRight: "10px",
                        backgroundColor: page === 0 ? "#ddd" : "#000",
                        color: "#fff",
                        borderRadius: "5px",
                        cursor: page === 0 ? "not-allowed" : "pointer",
                    }}
                >
                    Previous Page
                </button>
                <span style={{ padding: "10px 20px" }}>Page {page + 1}</span>
                <button
                    onClick={() => setPage((prev) => prev + 1)}
                    disabled={noMoreProducts}
                    style={{
                        padding: "10px 20px",
                        marginLeft: "10px",
                        backgroundColor: noMoreProducts ? "#ddd" : "#000",
                        color: "#fff",
                        borderRadius: "5px",
                        cursor: noMoreProducts ? "not-allowed" : "pointer",
                    }}
                >
                    Next Page
                </button>
            </div>
        </div>
    );
};

export default Products;

const defaultProd = {
    barCode: "",
    name: "",
    description: "",
    recommendedAttribute: "",
    mrp: null,
    purchasePrice: null,
    minSellPrice: null,
    price: null,
    membershipPrice: null,
    hsnCode: "",
    gst: null,
    urlKey: "",
    isOrder: false,
    isLastBuying: false,
    isSubscription: false,
    isMorningBuy: false,
    brand: {
        _id: "",
        image: [],
        tags: [],
        isRootBrand: false,
        childIds: [],
        isActive: false,
        name: "",
        _name: "",
        lName: "",
        description: "",
        lDescription: "",
        updated: null,
        created: null,
        date: null,
        id: null,
        __v: null,
        total: null,
    },
    subBrand: {
        _id: "",
        image: null,
        tags: [],
        isRootBrand: false,
        childIds: [],
        isActive: false,
        name: "",
        _name: "",
        lName: "",
        parentId: "",
        description: "",
        lDescription: "",
        updated: null,
        created: null,
        date: null,
        id: null,
        __v: null,
    },
    rootCatId: "",
    subCatId: "",
    leafCatId: "",
    images: [],
    tags: [],
    seo: {
        metaTitle: "",
        metaDescription: "",
        metaKeywords: "",
    },
    shipping: {
        dimensions: {
            height: null,
            length: null,
            width: null,
        },
        weight: null,
    },
    competitor: {
        grofers: {
            productId: null,
            lastPrice: null,
            merchantId: null,
        },
        milkbasket: {
            productId: null,
            lastPrice: null,
        },
        bigbasket: {
            productId: null,
            lastPrice: null,
        },
        grocio: {
            productId: null,
            lastPrice: null,
        },
    },
    parentId: "",
    isParent: false,
};
