import React, { useState, useEffect } from "react";
import { baseURL, liveURL } from "../../config/config";
import { useNavigate } from "react-router-dom";
import AutoFixHighOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { postDataSec } from "../../Apis/Api.js";
import Switch from "@mui/material/Switch";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const ProductCard = ({ item, index, disablebuttons }) => {
    const navigate = useNavigate();
    const [allowDescription, setAllowDescription] = useState(false);
    const [toggleChecked, setToggleChecked] = useState(item.isActive);

    // Sync toggle state with item.isActive when component mounts or updates
    useEffect(() => {
        setToggleChecked(item.isActive);
    }, [item.isActive]);

    // Fixing Syntax Error by properly closing the function
    const handleToggle = async () => {
        try {
            const newStatus = !toggleChecked; // Toggle the status
            setToggleChecked(newStatus); // Optimistic UI update

            const response = await postDataSec(
                baseURL + "/product/active/deactive",
                {
                    status: newStatus,
                    productId: item._id,
                }
            );

            if (response.success) {
                toast.info(
                    `${item.name} ${
                        newStatus ? "Activated" : "Deactivated"
                    } Successfully!`
                );
                setToggleChecked(newStatus);
            } else {
                toast.error("Failed to update product status.");
                setToggleChecked(!newStatus);
            }
        } catch (error) {
            console.error("Error updating product status:", error);
            toast.error("Error updating status.");
            setToggleChecked((prev) => !prev);
        }
    };

    useEffect(() => {
        if (localStorage.permissions) {
            const permissions = JSON.parse(localStorage.permissions);
            if (
                permissions.some(
                    (permission) =>
                        permission.name === "newDescription" && permission.read
                )
            ) {
                setAllowDescription(true);
            }
        }
    }, []);

    return (
        <tr key={item._id}>
            <td style={{ textAlign: "center" }}>{index}</td>
            <td>
                <img
                    alt="ProductImage"
                    src={`${liveURL}/public/product/${item.id}/${item.images[0]}`}
                    className="productImage"
                    style={{ width: 50, height: 50, objectFit: "cover" }}
                />
            </td>
            <td style={{ textAlign: "left" }}>
                <div
                    style={{ display: "flex", flexWrap: "wrap", width: "100%" }}
                >
                    <p
                        style={{
                            wordBreak: "break-word",
                            whiteSpace: "normal",
                            overflowWrap: "break-word",
                            flex: 1,
                            textTransform: "capitalize",
                        }}
                    >
                        {item?.name}
                    </p>
                </div>
            </td>

            <td style={{ textAlign: "right" }}>
                {item?.recommendedAttribute || "N/A"}
            </td>
            <td style={{ textAlign: "center" }}>
                {item?.shipping?.weight || "N/A"}
            </td>
            <td style={{ textAlign: "left" }}>{item?.sku}</td>
            <td style={{ textAlign: "left" }}>{item?.hsnCode || "N/A"}</td>

            {/* Toggle Switch */}
            <td className="action-column">
                <Switch
                    checked={toggleChecked}
                    onChange={handleToggle}
                    onClick={handleToggle}
                    disabled={disablebuttons}
                    color="primary"
                    //     onClick={handleToggle}
                />
                <button
                    disabled={disablebuttons}
                    className="edit-btn"
                    onClick={() =>
                        window.open(
                            `/editProduct/${item._id}/${item.categoryId}`
                        )
                    }
                >
                    <EditOutlinedIcon /> Edit
                </button>
                {allowDescription && (
                    <button
                        className="enhance-btn"
                        onClick={() =>
                            window.open(
                                `/newDescription/${item._id}/${item.categoryId}`
                            )
                        }
                    >
                        <AutoFixHighOutlinedIcon /> Enhance
                    </button>
                )}
            </td>
        </tr>
    );
};
