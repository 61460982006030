import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getDataSec } from "../../Apis/Api";
import { baseURL } from "../../config/config";

const CampaignView = () => {
    const location = useLocation();
    const arr = location.pathname.split("/");
    const campaignId = arr[arr.length - 1];
    const [campaign, setCampaign] = useState(null);

    const getCampaignDetail = async () => {
        try {
            const result = await getDataSec(
                `${baseURL}/campaign?id=${campaignId}`
            );
            if (result.success) {
                setCampaign(result.campaign);
            }
        } catch (error) {
            console.error("Error fetching campaign details:", error);
        }
    };

    useEffect(() => {
        getCampaignDetail();
    }, []);

    if (!campaign) {
        return <div>Loading...</div>;
    }

    return (
        <div
            style={{
                padding: "20px",
                maxWidth: "600px",
                margin: "auto",
                border: "1px solid #ddd",
                borderRadius: "8px",
                backgroundColor: "#f9f9f9",
            }}
        >
            <h1 style={{ textAlign: "center" }}>{campaign.name}</h1>
            <p>
                <strong>Description:</strong> {campaign.description}
            </p>
            <p>
                <strong>Amount:</strong> ${campaign.amount}
            </p>
            <p>
                <strong>Status:</strong> {campaign.status}
            </p>
            <p>
                <strong>Type:</strong> {campaign.type}
            </p>
            <h3>Sub Campaigns</h3>
            {campaign.subCampaigns.length > 0 ? (
                <ul>
                    {campaign.subCampaigns.map((sub, index) => (
                        <li key={index}>{sub.name}</li>
                    ))}
                </ul>
            ) : (
                <p>No sub campaigns available.</p>
            )}
            <h3>Users</h3>
            {campaign.users.length > 0 ? (
                <ul>
                    {campaign.users.map((user, index) => (
                        <li key={index}>{user}</li>
                    ))}
                </ul>
            ) : (
                <p>No users assigned.</p>
            )}
            <h3>Target Customers</h3>
            <p>{campaign.targetCustomers.length}</p>
        </div>
    );
};

export default CampaignView;
